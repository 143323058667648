import React from "react";

import './ButtonSelector.css';

const ButtonSelector = ({options, value, onChange}: {
    options: { value: string, text: string }[],
    value: string | null,
    onChange: (value: string | null) => void }) => {

    const keyHandler = (idx: number, e: React.KeyboardEvent) => {
        if (e.which === 32 || e.which === 13) {
            e.preventDefault();
            onChange(options[idx].value);
        }
    }

    return (
        <span className={`button-selector ${value ? "button-selector--value-selected" : ""}`}>
            {options.map((option, idx) => (
                <span className={`button-selector--option ${value === option.value ? "button-selector--option-selected" : ""}`}
                      key={idx}
                      tabIndex={0}
                      onClick={e => onChange(options[idx].value)}
                      onKeyPress={e => keyHandler(idx, e)}
                >{option.text}</span>
            ))}
        </span>);
}

export default ButtonSelector;