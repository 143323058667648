import Api from './Api';


const Auth = (function () {

    const AUTH_KEY = 'authToken';

    let token: string | null = localStorage.getItem(AUTH_KEY);

    return {
        async login(username: string, password: string): Promise<string> {
            const loginToken = await Api.login(username, password);

            if (loginToken) {
                token = loginToken;
                localStorage.setItem(AUTH_KEY, loginToken);
            }
            return loginToken;

        },
        isLoggedIn(): boolean {
            return token !== null;
        },
        getToken(): string | null {
            return token;
        },
        getUsername(): string | null {
            if (!token) return null;

            const payload = token.split('.', 3)[1];
            const data = JSON.parse(atob(payload));
            return data.username;
        },
        logout() {
            localStorage.removeItem(AUTH_KEY);
        }

    }
}());

export default Auth;