import Auth from './Auth';

const API_ROOT = '/api';

const Api = {

    async login(username: string, password: string): Promise<string> {
        const response = await fetch(API_ROOT + '/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ username, password })
        });

        const body = await response.json();

        if (!response.ok) {
            const e: ApiError = { body, response };
            throw e;
        }

        return body.token;
    },

    async getStats(): Promise<Stats> {
        const response = await fetch(API_ROOT + '/stats', {
            headers: {
                "Authorization": `Bearer ${Auth.getToken()}`
            }
        });

        return response.json()
    },

    async getEntries(): Promise<Transaction[]> {
        const response = await fetch(API_ROOT + '/entries', {
            headers: {
                "Authorization": `Bearer ${Auth.getToken()}`
            }
        });

        return response.json();
    },

    async register(type: TransactionType, person: Person, comment: string, value: number) {
        console.log('Regstering', type, person, comment, value);

        const response = await fetch(API_ROOT + '/entries', {
            method: 'POST',
            body: JSON.stringify({ userName: Auth.getUsername(), person, comment, value, type }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Auth.getToken()}`
            }
        });

        return response.json();
    }
};

export default Api;
