import React, {FormEvent, useState} from "react";
import Auth from "../service/Auth";
import { useFormInput } from "../util";

import './Login.css';

const LoginPage = ({onLogin}: { onLogin: () => void }) => {

    const [username, setUsername] = useFormInput('');
    const [password, setPassword] = useFormInput('');

    const [errorMessage, setErrorMessage] = useState(null);

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!username || !password) return;

        try {
            const token = await Auth.login(username, password);

            if (token) {
                onLogin();
                setErrorMessage(null);
            }
        } catch (e) {
            const apiError = e as ApiError;
            setErrorMessage(apiError.body.message);
        }
    }

    return <div className="login">
        <h3>Please log in</h3>

        {errorMessage && <span className="login--error">{errorMessage}</span>}

        <form onSubmit={onSubmit}>
            <input type="text" placeholder="Username" className="login--username" value={username}
                   onChange={setUsername}/>
            <input type="password" placeholder="Password" className="login--password" value={password}
                   onChange={setPassword} autoComplete="none"/>
            <button type="submit" className="login--submit">Log in</button>
        </form>
    </div>
}

export default LoginPage;
