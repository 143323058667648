import React from 'react';
import './BalanceStatus.css';
import {formatNok} from "../util";

const Person = (props: { name: string }) => <span className="balance--person">{props.name}</span>

const Amount = (props: { value: number }) => <span
    className="balance--amount">{formatNok(props.value)}</span>

const BalanceStatus = (props: { balance: Balance }) => (<div className="balance">
    <Person name={props.balance.debtor}/>
    {" skylder "}
    <Amount value={props.balance.debt}/>
</div>);

export default BalanceStatus;
