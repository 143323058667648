import {ChangeEvent, useState} from "react";

const formatNok = (øre: number) => (øre / 100.0).toLocaleString("nb", {style: 'currency', currency: 'NOK'});

function useFormInput(initialValue: string): [string, (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => any, (value: string) => any] {
    const [value, setValue] = useState(initialValue);
    const handleValueChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setValue(e.target.value);
    };
    return [
        value,
        handleValueChange,
        setValue
    ];
};

export {
    formatNok,
    useFormInput
}