import React from "react";
import Api from "../service/Api";
import Auth from "../service/Auth";
import TransactionForm from "./TransactionForm";
import StatsPanel from "./StatsPanel";
import BalanceStatus from "./BalanceStatus";
import TransactionList from "./TransactionList";

import './Index.css';

class IndexPage extends React.Component<{}, { stats?: Stats, transactions?: Transaction[] }> {

    constructor(props: any) {
        super(props);
        this.state = {
            stats: undefined,
            transactions: undefined
        }
    }

    async componentDidMount() {
      await this.reload();
    }

    async reload() {
        const stats = await Api.getStats();
        const entries = await Api.getEntries();

        this.setState({
            stats,
            transactions: entries.sort((a: Transaction, b: Transaction) => b.timestamp - a.timestamp)
        });
    }

    render() {
        const {stats, transactions} = this.state;

        const logout = () => {
            Auth.logout();
            window.location.reload();
        }

        const balance = stats ? {
            debt: stats.balance.debt || 0,
            debtor: stats.balance.debtor || 'Unknown'
        } : undefined;

        return <div className="jubebal">
            <header className="header">
                <span className="header--title">JubeBal</span>
                <div className="header--auth">
                    <span className="header--username">{Auth.getUsername()}</span>
                    <button className="header--logout" onClick={logout}>[log out]</button>
                </div>
            </header>
            <main className="main">
                <TransactionForm onChange={() => this.reload()}/>

                {stats && <StatsPanel stats={stats}/>}

                {balance && <BalanceStatus balance={balance}/>}
                {transactions && <TransactionList transactions={transactions}/>}
            </main>
        </div>
    }

}

export default IndexPage;
