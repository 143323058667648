import React from 'react';
import './StatsPanel.css';
import {formatNok} from "../util";

const StatsPanel = ({ stats }: { stats: Stats }) => {

    return <div className="stats">
        <span className="stats--expense-benedicte">Benedicte {formatNok(stats.benedicteStats.expenses)}</span>
        <span className="stats--expense-julian">Julian {formatNok(stats.julianStats.expenses)}</span>
    </div>

}

export default StatsPanel;
