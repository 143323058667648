import React from 'react';
import moment from 'moment';
import 'moment/locale/nb';
import './TransactionList.css';

import { formatNok } from '../util'

const EntryTs = (props: { value: number }) => {
    const ts = moment(props.value);
    const thisYear = ts.year() === moment().year();
    const formatString = thisYear ? 'D. MMM' : 'D. MMM YYYY';

    return (<span className="transaction--timestamp" title={ts.format()}>
        {ts.format(formatString)}
    </span>)
}

const Amount = (props: { value: number }) => <span className="transaction--amount">{formatNok(props.value)}</span>

const Entry = (props: { transaction: Transaction }) => (
    <div data-type={props.transaction.type} data-person={props.transaction.person} className="transaction">
        <EntryTs value={props.transaction.timestamp} />
        {props.transaction.comment}
        <Amount value={props.transaction.value}/>
    </div>
)

const TransactionList = (props: { transactions: Transaction[] }) => (
    <div className="transactions">
        <div className="transactions--list">
            {props.transactions.map(t => <Entry key={t.timestamp} transaction={t}/>)}
        </div>
    </div>)

export default TransactionList;
