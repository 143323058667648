import React, {useState, FormEvent} from 'react';
import { useFormInput } from "../util";
import './TransactionForm.css';

import ButtonSelector from '../components/ButtonSelector';
import Api from "../service/Api";


const RegisterTransaction = ({type, onChange}: { type: TransactionType, onChange: () => void }) => {
    const [ person, setPerson ] = useState<string | null>(null);
    const [ comment, setComment, setCommentRaw] = useFormInput('');
    const [ value, setValue, setValueRaw ] = useFormInput('');

    const [ validationError, setValidationError ] = useState<string | null>(null);

    const registerTransaction = (e: FormEvent) => {
        e.preventDefault();

        const m = /^([0-9 ]+)(?:[,.](\d{1,2}))?$/.exec(value);

        if (!person ||
            !value ||
            !m) {
            setValidationError('Something is wrong');
            return;
        }

        let valueNum = parseInt(m[1].replace(/\D+/g,''), 10) * 100;
        const decimalParts = m[2];
        if (decimalParts) {
            if (decimalParts.length === 1) {
                valueNum += parseInt(decimalParts, 10) * 10;
            } else if (decimalParts.length === 2) {
                valueNum += parseInt(decimalParts, 10);
            }
        }

        Api.register(type, person as Person, comment, valueNum).then(() => {
            setCommentRaw('');
            setValueRaw('');
            onChange();
        }).catch(e => {
            setValidationError('API error');
        })
    };

    return (
        <div className={`transaction-form--form transaction-form--form-${type.toLowerCase()}`}>
            <form onSubmit={registerTransaction}>
                <ButtonSelector options={[{value:'Julian',text:'Julian'}, {value:'Benedicte',text:'Benedicte'}]} value={person} onChange={value => setPerson(value)}/>

                {type === 'EXPENSE' ? " la ut kr " : ' overførte kr '}
                <input type="text"
                       name="value"
                       value={value}
                       placeholder="128,50"
                       className="transaction-form--input-amount transaction-form--input"
                       onChange={setValue}/>

                {type === 'EXPENSE' ? " for " : ' med kommentar '}
                <input type="text"
                       name="comment"
                       value={comment}
                       placeholder={type === 'EXPENSE' ? "Kolonialhandel" : "Tilbakebetaling"}
                       className="transaction-form--input-comment transaction-form--input"
                       onChange={setComment}/>

                <button type="submit" className={`transaction-form--submit ${validationError ? "transaction-form--submit-validation-error" :""}`}>{type === 'EXPENSE' ? 'Registrer utlegg' : 'Registrer overføring'}</button>
            </form>
        </div>

    )
}

const TransactionForm = ({ onChange }: { onChange: () => void }) => {
    const [state, setState] = useState<string | null>(null);


    return <div className="transaction-form">
        {state ? <div><RegisterTransaction type={state as TransactionType} onChange={onChange}/>
            <button onClick={() => setState(null)} className="transaction-form--cancel">Avbryt</button>
        </div> : <div>
            <ButtonSelector options={[{value:'EXPENSE',text:'Registrer utlegg'}, {value:'TRANSFER',text:'Registrer overføring'}]} value={state} onChange={val => setState(val)}/>
        </div>}
    </div>
}

export default TransactionForm;
