import React, {useState} from 'react';
import './App.css';

import moment from 'moment';
import 'moment/locale/nb';

import IndexPage from "./pages/Index";
import LoginPage from "./pages/Login";
import Auth from "./service/Auth";

moment.locale('nb');

const Jubebal = () => {
    const [isLoggedIn, setLoggedIn] = useState(Auth.isLoggedIn());

    return isLoggedIn ? <IndexPage/> : <LoginPage onLogin={() => setLoggedIn(true)}/>
}

export default Jubebal;
